.Mystifly-Airline-Image {
    background-position: -303px -1800px;
    min-width: 27px;
    min-height: 25px;
}

.mystifly-airline-def {
    background-position: -303px -1800px;
    width: 29px;
    height: 25px;
}

.mystifly-airline-2B {
    background-position: 0 0;
    width: 30px;
    height: 27px;
}

.mystifly-airline-3K {
    background-position: 0 -57px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-UK {
    background-position: -307px -1903px;
    width: 27px;
    height: 27px;
}
.mystifly-airline-4L {
    background-position: 0 -110px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-4Q {
    background-position: 0 -163px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-5H {
    background-position: 0 -216px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-5J {
    background-position: 0 -269px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-5T {
    background-position: 0 -322px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-6E {
    background-position: 0 -375px;
    width: 27px;
    height: 27px;
}

.mystifly-airline-8M {
    background-position: 0 -432px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-8U {
    background-position: 0 -485px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-9H {
    background-position: 0 -538px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-9W {
    background-position: 0 -591px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-9W1 {
    background-position: 0 -644px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-A3 {
    background-position: 0 -697px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-A9 {
    background-position: 0 -750px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AA {
    background-position: -120px -1992px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AB {
    background-position: 0 -856px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AC {
    background-position: 0 -909px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AE {
    background-position: 0 -962px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AF {
    background-position: 0 -1015px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AH {
    background-position: 0 -1068px;
    width: 27px;
    height: 21px;
}

.mystifly-airline-AI {
    background-position: -340px -318px;
    width: 27px;
    height: 20px;
}

.mystifly-airline-AK {
    background-position: 0 -1172px;
    width: 31px;
    height: 23px;
}

.mystifly-airline-AM {
    background-position: 0 -1225px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AP {
    background-position: 0 -1278px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AQ {
    background-position: 0 -1331px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AR {
    background-position: 0 -1384px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AS {
    background-position: 0 -1437px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AT {
    background-position: 0 -1490px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AV {
    background-position: 0 -1543px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AY {
    background-position: 0 -1596px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-AZ {
    background-position: 0 -1649px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-B3 {
    background-position: 0 -1702px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-B6 {
    background-position: 0 -1755px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BA {
    background-position: 0 -1808px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BD {
    background-position: 0 -1861px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BE {
    background-position: 0 -1914px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BG {
    background-position: 0 -1967px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BI {
    background-position: -61px 0;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BP {
    background-position: -61px -53px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BR {
    background-position: -61px -106px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BT {
    background-position: -61px -159px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-BY {
    background-position: -61px -212px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-CA {
    background-position: -61px -265px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-CB {
    background-position: -61px -318px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-CI {
    background-position: -61px -371px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-CM {
    background-position: -61px -424px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-CO {
    background-position: -61px -477px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-CX {
    background-position: -61px -530px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-CZ {
    background-position: -61px -583px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-D7 {
    background-position: -61px -636px;
    width: 31px;
    height: 23px;
}

.mystifly-airline-DC {
    background-position: -61px -689px;
    width: 27px;
    height: 17px;
}

.mystifly-airline-DJ {
    background-position: -61px -736px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-DL {
    background-position: -343px -151px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-DN {
    background-position: -61px -842px;
    width: 27px;
    height: 22px;
}

.mystifly-airline-EI {
    background-position: -61px -894px;
    width: 27px;
    height: 22px;
}

.mystifly-airline-EK {
    background-position: -61px -946px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-ET {
    background-position: -61px -999px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-EY {
    background-position: -308px -1219px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-F9 {
    background-position: -61px -1105px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-FD {
    background-position: -61px -1158px;
    width: 31px;
    height: 23px;
}

.mystifly-airline-FI {
    background-position: -61px -1211px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-FJ {
    background-position: -61px -1264px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-FL {
    background-position: -61px -1317px;
    width: 27px;
    height: 26px;
}

.mystifly-airline-FM {
    background-position: -61px -1373px;
    width: 27px;
    height: 19px;
}

.mystifly-airline-FT {
    background-position: -61px -1422px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-FZ {
    background-position: -61px -1475px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-G8 {
    background-position: -308px -1270px;
    width: 26px;
    height: 21px;
}

.mystifly-airline-G9 {
    background-position: -61px -1581px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-GA {
    background-position: -61px -1634px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-GE {
    background-position: -61px -1687px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-GF {
    background-position: -61px -1740px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-HA {
    background-position: -61px -1793px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-HM {
    background-position: -61px -1846px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-HP {
    background-position: -61px -1899px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-HR,
.mystifly-airline-H1 {
    background-position: -61px -1952px;
    width: 27px;
    height: 26px;
}

.mystifly-airline-HU {
    background-position: -122px 0;
    width: 27px;
    height: 23px;
}

.mystifly-airline-HX {
    background-position: -122px -53px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-HY {
    background-position: -122px -106px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-I7 {
    background-position: -122px -159px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-IA {
    background-position: -122px -212px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-IB {
    background-position: -122px -265px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-IC {
    background-position: -122px -318px;
    width: 30px;
    height: 23px;
}

.mystifly-airline-IG {
    background-position: -122px -371px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-IR {
    background-position: -122px -424px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-IT {
    background-position: -122px -477px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-IX,
.mystifly-airline-I5 {
    background-position: -342px -101px;
    width: 23px;
    height: 23px;
}

.mystifly-airline-J9 {
    background-position: -122px -572px;
    width: 25px;
    height: 14px;
}

.mystifly-airline-JK {
    background-position: -122px -616px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-JL {
    background-position: -122px -669px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-JM {
    background-position: -122px -722px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-JO {
    background-position: -122px -775px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-JP {
    background-position: -122px -828px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KA {
    background-position: -122px -881px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KB {
    background-position: -122px -934px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KC {
    background-position: -122px -987px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KD {
    background-position: -122px -1040px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KE {
    background-position: -122px -1093px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KL {
    background-position: -122px -1146px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KM {
    background-position: -122px -1199px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KQ {
    background-position: -122px -1252px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KU {
    background-position: -122px -1305px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-KX {
    background-position: -122px -1358px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-LA {
    background-position: -122px -1411px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-LB {
    background-position: -122px -1464px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-LH {
    background-position: -122px -1517px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-LO {
    background-position: -122px -1570px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-LP {
    background-position: -122px -1623px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-LR {
    background-position: -122px -1676px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-LX {
    background-position: -122px -1729px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-LY {
    background-position: -122px -1782px;
    width: 27px;
    height: 21px;
}

.mystifly-airline-M1 {
    background-position: -122px -1833px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MA {
    background-position: -122px -1886px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MD {
    background-position: -122px -1939px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MF {
    background-position: -183px 0;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MH {
    background-position: -183px -53px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MI {
    background-position: -183px -106px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MK {
    background-position: -183px -159px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MS {
    background-position: -183px -212px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MU {
    background-position: -183px -265px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MULT {
    background-position: -183px -318px;
    width: 27px;
    height: 22px;
}

.mystifly-airline-MV {
    background-position: -183px -370px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-MX {
    background-position: -183px -423px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-NF {
    background-position: -183px -476px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-NH {
    background-position: -183px -529px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-NU {
    background-position: -183px -582px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-NW {
    background-position: -183px -635px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-NX {
    background-position: -183px -688px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-NZ {
    background-position: -183px -741px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-OA {
    background-position: -183px -794px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-OS {
    background-position: -183px -847px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-OU {
    background-position: -183px -900px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-OV {
    background-position: -306px -1537px;
    width: 30px;
    height: 30px;
}

.mystifly-airline-OZ {
    background-position: -183px -1006px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-PC {
    background-position: -183px -1059px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-PG {
    background-position: -183px -1112px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-PK {
    background-position: -183px -1165px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-PR {
    background-position: -183px -1218px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-PS {
    background-position: -183px -1271px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-PW {
    background-position: -183px -1324px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-PX {
    background-position: -183px -1377px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-QF {
    background-position: -183px -1430px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-QR {
    background-position: -183px -1483px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-QV {
    background-position: -183px -1536px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-QZ {
    background-position: -183px -1589px;
    width: 31px;
    height: 23px;
}

.mystifly-airline-RA {
    background-position: -183px -1642px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-RC {
    background-position: -183px -1695px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-RI {
    background-position: -183px -1748px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-RJ {
    background-position: -183px -1801px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-RO {
    background-position: -183px -1854px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-S2 {
    background-position: -183px -1907px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-S7 {
    background-position: -183px -1960px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SA {
    background-position: -244px 0;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SB {
    background-position: -244px -53px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SC {
    background-position: -244px -106px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SG {
    background-position: -244px -159px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SK {
    background-position: -244px -212px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SN {
    background-position: -244px -265px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SQ {
    background-position: -244px -318px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SR {
    background-position: -244px -371px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SS {
    background-position: -244px -424px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SU {
    background-position: -244px -477px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SV {
    background-position: -244px -530px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-SY {
    background-position: -244px -583px;
    width: 25px;
    height: 25px;
}

.mystifly-airline-T3 {
    background-position: -244px -638px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TA {
    background-position: -244px -691px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TC {
    background-position: -244px -744px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TE {
    background-position: -244px -797px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TG {
    background-position: -244px -850px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TK {
    background-position: -244px -903px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TN {
    background-position: -244px -956px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TP {
    background-position: -244px -1009px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TR {
    background-position: -244px -1062px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TU {
    background-position: -244px -1115px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-TZ {
    background-position: -244px -1168px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-U2 {
    background-position: -244px -1221px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-U7 {
    background-position: -244px -1274px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-UA {
    background-position: -61px -475px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-UL {
    background-position: -244px -1380px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-UN {
    background-position: -244px -1433px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-UO {
    background-position: -244px -1486px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-UP {
    background-position: -244px -1539px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-US {
    background-position: -244px -1592px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-UX {
    background-position: -244px -1645px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VA {
    background-position: -244px -1698px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VG {
    background-position: -244px -1751px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VH {
    background-position: -244px -1804px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VI {
    background-position: -244px -1857px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VK {
    background-position: -244px -1910px;
    width: 27px;
    height: 19px;
}

.mystifly-airline-VN {
    background-position: -244px -1959px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VS {
    background-position: -305px 0;
    width: 27px;
    height: 19px;
}

.mystifly-airline-VSPL {
    background-position: -305px -49px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VT {
    background-position: -305px -102px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VV {
    background-position: -305px -155px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VW {
    background-position: -305px -208px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VX {
    background-position: -305px -261px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-W3 {
    background-position: -305px -314px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-W5 {
    background-position: -305px -367px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-WB {
    background-position: -305px -420px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-WF {
    background-position: -305px -473px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-WP {
    background-position: -305px -526px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-WX {
    background-position: -305px -579px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-WY {
    background-position: -305px -632px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-XR {
    background-position: -305px -685px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-YM {
    background-position: -305px -738px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-YX {
    background-position: -305px -791px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-Z2 {
    background-position: -305px -844px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-Z5 {
    background-position: -305px -897px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-ZB {
    background-position: -305px -950px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-ZG {
    background-position: -305px -1003px;
    width: 30px;
    height: 19px;
}

.mystifly-airline-ZH {
    background-position: -305px -1052px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-ZL {
    background-position: -305px -1105px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-ZQ {
    background-position: -305px -1158px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-VF {
    background-position: -304px -1642px;
    width: 27px;
    height: 23px;
}

.mystifly-airline-HG {
    background-position: -301px -1696px;
    width: 31px;
    height: 23px;
}

.mystifly-airline-DY {
    background-position: -302px -1752px;
    width: 31px;
    height: 22px;
}

.mystifly-airline-OD {
    background-position: -309px -1955px;
    width: 24px;
    height: 24px;
}

.mystifly-airline-SL {
    background-position: -309px -1955px;
    width: 23px;
    height: 26px;
}

.mystifly-airline-VZ {
    background-position: -308px -1998px;
    width: 27px;
    height: 27px;
}

.mystifly-airline-WE {
    background-position: -244px -1994px;
    width: 31px;
    height: 22px;
}

.mystifly-airline-NK {
    background-position: -307px -1379px;
    width: 26px;
    height: 26px;
}

.mystifly-airline-JQ {
    background-position: -306px -1432px;
    width: 26px;
    height: 26px;
}

.mystifly-airline-GP {
    background-position: -346px -204px;
    width: 26px;
    height: 26px;
}

.mystifly-airline-QP {
    background-position: -306px -1482px;
    height: 27px;
    width: 27px;
}
.mystifly-airline-OG {
    background-position: -303px -1588px;
    height: 32px;
    width: 32px;
}

.mystifly-airline-XY {
    background-position: -348px -44px;
    width: 26px;
    height: 26px;
}

.mystifly-airline-XC {
    background-position: -344px -258px;
    width: 30px;
    height: 30px;
}

.longlayover {
    background-position: -305px -1211px;
    width: 26px;
    height: 25px;
}

.overnight {
    background-position: -305px -1266px;
    width: 26px;
    height: 25px;
}

.redeye {
    background-position: -305px -1321px;
    width: 26px;
    height: 25px;
}

.remove {
    background-position: -305px -1376px;
    width: 75px;
    height: 60px;
}

.mystifly-Airline-Image {
    /* background-image: url("/assets/images/mystifly-sprites.png"); */
    display: inline-block;
    position: absolute;
}

.airlineIcon {
    /* background-image: url("/assets/images/mystifly-sprites.png"); */
    display: inline-block;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    width: 30px;
    height: 30px;
}
